import React, { useState, useEffect } from 'react';
import YouTube from 'react-youtube';
import { useNavigate } from 'react-router-dom'; // 引入 useNavigate hook
import { EuiPanel, EuiIcon,EuiFlexGroup, EuiFlexItem, EuiButton, EuiSelect, EuiButtonIcon, EuiListGroup, EuiListGroupItem, EuiModal, EuiModalHeader, EuiModalBody, EuiModalFooter, EuiFieldText, EuiTextArea,  EuiDragDropContext,
    EuiDroppable,
    EuiDraggable, 
    EuiButtonEmpty,
    EuiText,
    copyToClipboard,
    euiDragDropReorder} from '@elastic/eui';
import {TranslateLanguage} from './KeywordTranslator';
import '../style.css';
import logosmall from '../assets/CTBLogoCircle-Small.png';
import axios from 'axios';
import { useAlert } from './AlertProvider';

const apiURL = process.env.REACT_APP_MasterApiURL;
const apiKey = process.env.REACT_APP_APIKey;

const opts = {
    height: '100%',
    width: '100%',
    playerVars: {
        autoplay: 1,
        playsinline: 1,
        enablejsapi: 1,
        fullscreen: 1
    },
};

const onPlayerError = (event) => {
    console.error('Error occurred while playing video:', event.data);
};

const onReady = (e) => {
    e.target.playVideo();
}

function FavoritePageWithPlayer() {
    const [playlists, setPlaylists] = useState([]);
    const [selectedPlaylist, setSelectedPlaylist] = useState(null);
    const [songs, setSongs] = useState([]);
    const [currentSong, setCurrentSong] = useState({ id: '', name: '' });
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [newPlaylistName, setNewPlaylistName] = useState('');
    const [currentResource, setCurrentResource] = useState('');
    const [newTags, setNewTags] = useState('');
    const [isPanelVisible, setIsPanelVisible] = useState(true); // 控制右侧面板是否可见
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [newPlaylistVisibility, setNewPlaylistVisibility] = useState('private');
    const [newPlaylistDescription, setNewPlaylistDescription] = useState('');
    const [newPlaylistTags, setNewPlaylistTags] = useState('');
    const [newPlaylistCategory, setNewPlaylistCategory] = useState('');
    const [expandedPlaylist, setExpandedPlaylist] = useState(null); // 用于记录当前展开的收藏单
    const [playlistDetails, setPlaylistDetails] = useState(null); // 用于存储收藏单详情数据
    const [shareLink, setShareLink] = useState("");
    const navigate = useNavigate(); // 使用 useNavigate 创建 navigate 函数
    const [isPortrait, setIsPortrait] = useState(window.innerHeight > window.innerWidth);
    const showAlert = useAlert();
    const [verses, setVerses] = useState(''); // 使用 useState 定义 verses

    // 从 sessionStorage 获取 userId
    const user = JSON.parse(sessionStorage.getItem('user'));
    const userId = user ? user.id : null;

    useEffect(() => {
        axios.get(`/api/playlists`, {
            params: {
                userId: userId,
                type: 'favorite'
            }
        })
        .then(response => {
            const data = response.data;
            // console.log('Parsed JSON:', data);
            setPlaylists(data);
        })
        .catch(error => {
            console.error('Error fetching playlists:', error);
        });
    }, []);

    useEffect(() => {
        const handleResize = () => {
          setIsPortrait(window.innerHeight > window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }, []);

    const panelStyle = {
        position: 'sticky',
        top: 0,
        zIndex: 100,
        height: isPanelVisible 
        ? (isPortrait ? '50vh' : '90vh') // When panel is visible
        : (isPortrait ? '90vh' : '90vh') // When panel is not visible
      };

    const handlePlaylistChange = (playlistId) => {
        if (expandedPlaylist === playlistId) {
            setExpandedPlaylist(null); // 如果点击的收藏单已经展开，则合上
        } else {
            setSelectedPlaylist(playlistId);
            axios.get(`/api/playlists/${playlistId}/resources`)
            .then(response => {
                if (response.status !== 200) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const sortedReses = response.data.sort((a, b) => a.order - b.order); // 按 order 排序
                setSongs(sortedReses);
                setExpandedPlaylist(playlistId); // 展开新的收藏单
            })
            .catch(error => {
                console.error('Error fetching resources:', error);
            });
        }
    };
    //删除收藏項目的处理函数
    const handleDeleteSong = (songId) => {
        if (window.confirm(TranslateLanguage(localStorage.userLanguage, "確定從收藏單中刪除？"))) {
            axios.delete(`/api/playlists/${selectedPlaylist}/songs/${songId.resourceId}`)
            .then(response => {
                if (response.status === 200) {
                    // 删除成功后更新前端的收藏项列表
                    setSongs(songs.filter(song => song.resourceId !== songId.resourceId));
                    showAlert(TranslateLanguage(localStorage.userLanguage, "刪除成功"));
                } else {
                    showAlert(TranslateLanguage(localStorage.userLanguage, "刪除失敗，請重試"));
                }
            })
            .catch(error => {
                console.error("删除收藏項目时出错:", error);
                showAlert(TranslateLanguage(localStorage.userLanguage, "刪除失敗，請重試"));
            });
        }
    };    
    // 当 playlists 加载完成后，自动选择第一个收藏单并触发其点击事件
    useEffect(() => {
        if (playlists.length > 0) {
            handlePlaylistChange(playlists[0].id);  // 自动选择第一个收藏单
        }
        else {
            setSelectedPlaylist(null);
        }
    }, [playlists]);

    // 当收藏項目列表(songs)更新时，自动播放第一个收藏单的第一個收藏項目
    useEffect(() => {
        if (songs.length > 0) {
            setCurrentSong(songs[0]);
        }
    }, [songs]);

    const handleSongClick = (song) => {
        setCurrentSong(song);
    };

    const getTextAlignment = (resource) => {
        if (resource && resource.resourceType === '經文') { 
            return 'right'; // 如果是經文，文本靠右
        }
        return 'left'; // 其他情况文本靠左
    };

    function setViewportDimensions() {
        let height = 44;
        let calwidth;
        let calheight;
        calwidth = window.innerWidth - 30;
        calheight = window.innerHeight - height;
        if (calwidth*9/16 > calheight)
            document.documentElement.style.setProperty('--viewport-height', `${window.innerHeight - height}px`);
        else
            document.documentElement.style.setProperty('--viewport-height', `${calwidth*9/16}px`);
        document.documentElement.style.setProperty('--viewport-width', `${window.innerWidth - 20}px`);
    }
    
    // 初始化时设置值
    setViewportDimensions();
    
    // 监听窗口大小变化事件
    window.addEventListener('resize', setViewportDimensions); 

    function extractContent(stringHTML) {
        var content =""
        var span = document.createElement('span');
        span.innerHTML = stringHTML;
        var contextExtracted = span.getElementsByTagName("table")[0];
        if(contextExtracted){
          contextExtracted.getElementsByTagName("u")[0].remove();
          contextExtracted.querySelectorAll("span, p, strong")
          .forEach(el => el.textContent.trim() === "" && el.parentNode.removeChild(el));
          content = contextExtracted.innerHTML;  
        }
        
        return content;
      };
      
    const textTitleStyle = {
        fontSize: '20px',
        lineHeight: '1.6',
        color: '#333',
        padding: '15px',
        whiteSpace: 'pre-wrap',
        overflowY: 'auto',
        textAlign: 'center',
        fontWeight: '600',
    };

    const textContainerStyle = {
        height: isPanelVisible
            ? (isPortrait ? 'calc(50vh - 52px)' : 'calc(90vh - 52px)')
            : (isPortrait ? 'calc(90vh - 52px)' : 'calc(90vh - 52px)'),
        overflow: 'auto',
        padding: '10px',
        border: '1px solid #ccc',
        borderRadius: '5px',
        backgroundColor: '#f9f9f9',
    };
    const textContainerStyleWithTitle = {
        height: isPanelVisible
            ? (isPortrait ? 'calc(50vh - 100px)' : 'calc(90vh - 100px)')
            : (isPortrait ? 'calc(90vh - 100px)' : 'calc(90vh - 100px)'),
        overflow: 'auto',
        padding: '10px',
        border: '1px solid #ccc',
        borderRadius: '5px',
        backgroundColor: '#f9f9f9',
    };
    const textVerseContainerStyle = {
        height: isPanelVisible
            ? (isPortrait ? 'calc(50vh - 52px)' : 'calc(90vh - 52px)')
            : (isPortrait ? 'calc(90vh - 52px)' : 'calc(90vh - 52px)'),
        border: '1px solid #ccc',
        borderRadius: '5px',
        fontSize: '20px', // 字体大小
        lineHeight: '1.8', // 行高
        padding: '20px', // 内边距
        margin: '10px', // 外边距，避免容器紧贴其他元素
        color: '#333', // 字体颜色，深灰色
        backgroundColor: '#f7f7f7', // 背景颜色，淡灰色
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', // 阴影效果，使容器更加立体
        overflowY: 'auto', // 支持滚动
        maxHeight: '70vh', // 限制容器高度，避免页面过长
        whiteSpace: 'pre-wrap', // 保持空白和换行
        fontWeight: '520', // 设置文字粗细为中等粗
    };
      
    useEffect(() => {
    if (!currentSong.engineID) return;
            const [enginePrefix, engineNumberStr] = currentSong.engineID.split("|");
            const engineNumber = parseInt(engineNumberStr, 10);
            setCurrentResource(null);
            fetch(apiURL, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json, charset=UTF-8",
                    "Authorization": "Bearer search-" + apiKey
                },
                body: JSON.stringify({
                    query: "",
                    filters: { "any": { "id": currentSong.engineID } },
                    page: { size: 1000 },
                    sort: { id: "asc" }
                })
            })
            .then(response => response.json())
            .then(data => {
                const results = data.results;
                if (results.length > 0) {
                    const result = results[0];
                    if (result.doctype.raw === "禱告") {
                        setCurrentResource(result.prayer.raw);
                    } else if (result.doctype.raw === "經文") {
                        const verseNumber = parseInt(result.verse_number.raw, 10);
                        let startVerse, endVerse;
                        // 解析 description 字段，获取起始和结束节
                        const descriptionRange = currentSong.description.split(",");
                        if (
                            descriptionRange.length === 2 &&
                            descriptionRange[0] !== descriptionRange[1]
                        ) {
                            startVerse = parseInt(descriptionRange[0], 10);
                            endVerse = parseInt(descriptionRange[1], 10);
                        } else {
                            startVerse = verseNumber;
                            endVerse = verseNumber;
                        }
                        // 计算新的 engineID 范围
                        const startEngineID = `${enginePrefix}|${engineNumber + startVerse - verseNumber}`;
                        const endEngineID = `${enginePrefix}|${engineNumber + endVerse - verseNumber}`;
                        if (startEngineID !== endEngineID)
                        {
                            // 生成需要的 ID 列表
                            const idList = [];
                            for (let i = startVerse; i <= endVerse; i++) {
                                idList.push(`${enginePrefix}|${engineNumber + i - verseNumber}`);
                            }
                            // 发起新的查询以获取多节经文
                            fetch(apiURL, {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json, charset=UTF-8",
                                    "Authorization": "Bearer search-" + apiKey
                                },
                                body: JSON.stringify({
                                    query: "",
                                    filters: {
                                        "any": {
                                            "id": idList // 使用批量ID查询
                                        }
                                    },
                                    page: { size: 1000 },
                                    sort: { id: "asc" }
                                })
                            })
                            .then(response => response.json())
                            .then(data => {
                                const newVerses  = data.results
                                    .map(v => `${v.verse_number.raw} ${v.title.raw}`) // 在每段前面添加節數
                                    .join(" ");
                                setVerses(newVerses); 
                                setCurrentResource(`${result.chapter_name.raw} ${result.chapter_number.raw}: ${startVerse}-${endVerse}`);
                            })
                            .catch(() => {
                                setCurrentResource(null);
                            });
                        }
                        else{
                            setVerses(currentSong.name);
                            setCurrentResource(`${result.chapter_name.raw} ${result.chapter_verse_number.raw}`);
                        }                            
                    } else if (["拾穗", "註解", "例證", "綱目"].includes(result.doctype.raw)) {
                        let resultText = formatText(result.title.raw);
                        const resultLink = result.link.raw;
                        loadContentLocal(resultLink, resultText);
                    } else if (result.doctype.raw === "網路") {
                        setCurrentResource(result.description.raw);
                    } else {
                        setCurrentResource(null);
                    }
                } else {
                    setCurrentResource(null);
                }
            })
            .catch(() => {
                setCurrentResource(null);
            });
        }, [currentSong, apiURL, apiKey]);
      
    const loadContentLocal = async (filePath, resultText) => {
        filePath = filePath.replace("https://www.ccbiblestudy.org/New Testament", "")
                            .replace("https://www.ccbiblestudy.org/Old Testament", "");
        const finalFilePath = `${window.location.origin}/ccbiblestudy${filePath}`;

        const response = await fetch(finalFilePath);
        const data = await response.text();
        const finalResult = extractContent(data);
        setCurrentResource(finalResult || resultText);
    };

    const formatText = (text) => {
        return text.split("壹、內容綱要").join("<p>壹、內容綱要</p>")
                    .split("貳、逐節詳解").join("<p>貳、逐節詳解</p>")
                    .split("叁、靈訓要義").join("<p>叁、靈訓要義</p>")
                    .split("〔呂振中譯〕").join("<br>〔呂振中譯〕<br>")
                    .split("〔原文字義〕").join("<br>〔原文字義〕<br>")
                    .split("〔文意註解〕").join("<br>〔文意註解〕<br>")
                    .split("〔話中之光〕").join("<br>〔話中之光〕<br>")
                    .split("【").join("<p>【")
                    .split("】").join("】</p>")
                    + "<p>。。。（前往原始網站查看更多）";
    };
      

    const handleEditClick = () => {
        if (selectedPlaylist) {
            axios.get(`/api/playlists/${selectedPlaylist}`)
            .then(response => {
                if (response.status !== 200) {
                    throw new Error('Network response was not ok');
                }
                const data = response.data;
                setPlaylistDetails(data);
                setNewPlaylistName(data.name);
                setNewPlaylistVisibility(data.visibility);
                setNewPlaylistDescription(data.description);
                setNewPlaylistTags(data.tags);
                setNewPlaylistCategory(data.category);
                setIsEditModalVisible(true);
            })
            .catch(error => {
                console.error('Error fetching playlist details:', error);
            });
        }
    };    

    // 处理播放结束事件，自动播放下一個收藏
    const handleSongEnd = () => {
        const currentIndex = songs.findIndex(song => song.resourceId === currentSong.resourceId);
        const nextIndex = (currentIndex + 1) % songs.length;
        if (nextIndex === 0) {
            setCurrentSong(songs[0]);  // 回到第一個收藏
        } else {
            setCurrentSong(songs[nextIndex]);
        }
    };     
    //保存修改後的收藏單信息
    const handleSaveChanges = () => {
        const requestBody = {
            name: newPlaylistName,
            visibility: newPlaylistVisibility,
            description: newPlaylistDescription,
            tags: newPlaylistTags,
            category: newPlaylistCategory
        };
    
        axios.put(`/api/playlists/${selectedPlaylist}`, requestBody, {
            headers: {
                'Content-Type': 'application/json',
                // Authorization 由全局拦截器自动处理
            }
        })
        .then(() => {
            setIsEditModalVisible(false);
            const updatedPlaylists = playlists.map(p => p.id === selectedPlaylist ? { ...p, ...requestBody } : p);
            setPlaylists(updatedPlaylists);
        })
        .catch(error => {
            console.error('Error updating playlist:', error);
        });
    };    

      // 打开新建收藏单模态窗口
    const openCreatePlaylistModal = () => {
        setNewPlaylistName("");
        setNewPlaylistDescription("");
        setNewPlaylistTags("");
        setNewPlaylistCategory("");
        setShowCreateModal(true);
    };

    // 关闭新建收藏单模态窗口
    const closeCreatePlaylistModal = () => {
        setShowCreateModal(false);
    };

    // 创建新收藏单
    const handleCreatePlaylist = () => {
        const requestBody = {
            userId: user.id,
            user: user,
            name: newPlaylistName,
            type: 'favorite',
            visibility: newPlaylistVisibility,
            description: newPlaylistDescription,
            tags: newPlaylistTags,
            category: newPlaylistCategory
        };

        // console.log("请求体:", requestBody); // 打印请求体用于调试

        axios.post(`/api/playlists/create`, requestBody, {
            headers: {
                'Content-Type': 'application/json',
                // Authorization 由全局拦截器自动处理
            }
        })
        .then(response => {
            const data = response.data;
            // console.log('Playlist created:', data);
            showAlert(TranslateLanguage(localStorage.userLanguage, "收藏單創建成功"));
            closeCreatePlaylistModal();
            
            // 重新获取收藏单列表或刷新页面
            setPlaylists([...playlists, { id: data.playlistId, name: newPlaylistName }]);
        })
        .catch(error => {
            if (error.response) {
                console.error('响应错误:', error.response.data);
                console.error('状态码:', error.response.status);
            } else if (error.request) {
                console.error('请求未响应:', error.request);
            } else {
                console.error('请求配置错误:', error.message);
            }
        });
    };

    //生成分享鏈接
    const handleSharePlaylistClick = () => {
        if (songs.length == 0){
            showAlert(TranslateLanguage(localStorage.userLanguage, "收藏單中沒有内容，請添加后再分享。"));
        }
        else {
            const urlPrefix = window.location.origin;
            axios.get(`/api/playlists/share/${selectedPlaylist}`)
            .then(response => {
                const data = response.data;
                if (data.shareLink) {
                    const shareLink = `${urlPrefix}${data.shareLink}`;
                    setShareLink(shareLink);
                } else {
                    showAlert(TranslateLanguage(localStorage.userLanguage, "無法生成分享鏈接，可能收藏單是私有的。"));
                }
            })
            .catch(error => {
                console.error("生成分享链接时出错:", error);
                showAlert(TranslateLanguage(localStorage.userLanguage, "生成分享鏈接失敗，請點擊“詳情”設置為“公開”！"));
            });
        }
    };

    //刪除收藏單
    const handleDeletePlaylistClick = () => {
        if (window.confirm(TranslateLanguage(localStorage.userLanguage, "確定刪除這個收藏單？"))) {
            axios.delete(`/api/playlists/${selectedPlaylist}`)
            .then(response => {
                if (response.status === 200) {
                    // 从本地状态中移除被删除的收藏单
                    setPlaylists(playlists.filter(playlist => playlist.id !== selectedPlaylist));
                    showAlert(TranslateLanguage(localStorage.userLanguage, "收藏單刪除成功"));
                } else {
                    showAlert(TranslateLanguage(localStorage.userLanguage, "刪除失敗，請重試"));
                }
            })
            .catch(error => {
                console.error("删除收藏單时出错:", error);
                showAlert(TranslateLanguage(localStorage.userLanguage, "刪除失敗，請重試"));
            });
        }
    };    

    const copyShareLink =()=> {
        copyToClipboard(shareLink);
        setShareLink(false)
      };

    const onDragEnd = ({ source, destination }) => {
        if (source && destination) {
          const reorderedSongs = euiDragDropReorder(songs, source.index, destination.index);
          setSongs(reorderedSongs);
            const songOrders = reorderedSongs.map((song, index) => ({
                songId: song.resourceId, 
                order: index + 1  // `index` 是从 0 开始的，因此我们加 1
            }));
        
            const payload = {
                playlistId: selectedPlaylist,
                songOrders: songOrders
            };
            axios.post(`/api/playlists/${selectedPlaylist}/reorder`, payload, {
                headers: {
                    'Content-Type': 'application/json',
                    // Authorization 由全局拦截器自动处理
                }
            })
            .then(response => {
                if (response.status !== 200) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                setSongs([...reorderedSongs]);
            })
            .catch(error => {
                console.error('Error saving song order:', error);
            });
        }
      };
    
    const togglePanel = () => {
        setIsPanelVisible(!isPanelVisible);
    };

    return (
        <EuiFlexGroup className="responsive-flex-group">
            {/* 左侧播放窗口 */}
            <EuiFlexItem grow={isPanelVisible ? 2 : 3} style={{ position: 'sticky', top: 0,  zIndex: 100 }} className={isPanelVisible ? "left_video_item":  "left_video_item_full"}>
                {/* <EuiPanel style={panelStyle}> */}
                <EuiPanel                    
                    style={{
                        display: 'flex',
                        flexDirection: 'column', // 垂直排列子元素
                        justifyContent: 'center', // 水平居中对齐
                        alignItems: 'center', // 子元素在垂直方向居中
                        padding: '5px', // 可根据需要调整面板的内边距
                        }} 
                >
                    {currentSong ? (
                        currentSong.labels === "v" ? (
                        <>
                        {/* Title Section */}
                        <h3 style={{ textAlign: 'center', margin: 10, padding: 0 }}>
                            {TranslateLanguage(localStorage.userLanguage, currentSong.name)}
                        </h3>
                        <div style={{ width: '100%', position: 'relative', paddingTop: '56.25%' }}>
                            <YouTube
                                id="ytPlayer"
                                videoId={currentSong.resourceId}
                                className={isPanelVisible ? 'playlist-player' : 'playlist-player-full'}
                                onReady={onReady}
                                opts={opts}
                                onError={onPlayerError}
                                onEnd={handleSongEnd}  // Handle end of song
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: '50%',
                                    transform: 'translateX(-50%)',
                                    width: '90%',
                                    height: '90%',
                                    marginTop: '0',  // Ensures no additional margin between video and title
                                }}
                            />
                        </div>
                        </>
                        ) : (
                                <div style={panelStyle}>
                                <>
                                {!(["拾穗", "註解", "例證", "綱目"].includes(currentSong.resourceType)) && (
                                    <>
                                    {(currentSong.resourceType === '經文') && (
                                        <div>
                                            <div style={textVerseContainerStyle}>
                                                <span>{TranslateLanguage(localStorage.userLanguage, verses)}</span>
                                            </div>
                                            <div style={{...textTitleStyle, textAlign: getTextAlignment(currentSong) }}>
                                                <EuiText>
                                                    <span>{TranslateLanguage(localStorage.userLanguage, currentResource)}</span>
                                                </EuiText>
                                            </div>
                                        </div>
                                    )}
                                    {!(currentSong.resourceType === '經文') && (
                                        <div>
                                            <div style={textTitleStyle}>
                                                <span>{TranslateLanguage(localStorage.userLanguage, currentSong.name)}</span>
                                            </div>
                                            <div style={textContainerStyleWithTitle}>
                                                <EuiText>
                                                    <span>{TranslateLanguage(localStorage.userLanguage, currentResource)}</span>
                                                </EuiText>
                                            </div>
                                        </div>
                                    )}
                                    </>
                                )}
                                {["拾穗", "註解", "例證", "綱目"].includes(currentSong.resourceType) && (
                                    <div style={textContainerStyle}>
                                        <EuiText>
                                            <span dangerouslySetInnerHTML={{__html: TranslateLanguage(localStorage.userLanguage, currentResource)}} />
                                        </EuiText>
                                    </div>
                                )}
                                </>
                                <div style={{ textAlign: 'right', marginTop: '10px' }}>
                                    {currentSong.link && (
                                        <EuiButton 
                                            size="s" 
                                            fill 
                                            onClick={() => window.open(currentSong.link, '_blank')}
                                            style={{ backgroundColor: '#0077c2', color: '#fff' }}
                                        >
                                            {TranslateLanguage(localStorage.userLanguage, "鏈接來源")}
                                        </EuiButton>
                                    )}
                                </div>
                            </div>
                            )
                    ) : (
                        <p>{TranslateLanguage(localStorage.userLanguage, "請點擊收藏項目播放")}</p>
                    )}
                </EuiPanel>
            </EuiFlexItem>

            {/* 面板切换按钮 */}
            <EuiFlexItem grow={false} style={{ zIndex: 1 }} className='center_toggle_button'>
                <EuiFlexGroup className="panel-toggle-button" direction="column" alignItems="center" justifyContent="center">
                    <EuiFlexItem grow={false}>
                        <EuiButtonIcon
                            iconType={isPanelVisible ? 'arrowRight' : 'arrowLeft'}
                            onClick={togglePanel}
                            aria-label="Toggle Playlist Panel"
                        />
                    </EuiFlexItem>
                </EuiFlexGroup>
            </EuiFlexItem>

            {/* 右侧收藏单及收藏項目显示 */}
            {isPanelVisible && (
                <EuiFlexItem grow={1} style={{ height: '100vh', overflowY: 'auto' }} className="rigth_song_info">
                    <EuiPanel style={{ width: '100%', textAlign: 'center', zIndex: 1, display: 'flex', flexDirection: 'column' }}> {/* 确保整个面板宽度一致 */}
                        {/* 顶部Logo和标题部分 */}
                        <div 
                            style={{ 
                                display: 'flex', 
                                flexDirection: 'column', 
                                alignItems: 'center', 
                                cursor: 'pointer', 
                                marginBottom: '15px', 
                                width: '100%' 
                            }} 
                            onClick={() => navigate('/')}
                        >
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <img src={logosmall} alt="Logo" style={{ width: '50px', height: '50px', marginBottom: '10px' }} />
                                <h2 style={{ margin: 10, textAlign: 'center' }}>{user ? `${TranslateLanguage(localStorage.userLanguage, user.username)}的收藏单` : '我的收藏单'}</h2>
                            </div>
                        </div>
                        {/* 收藏单和收藏項目列表部分 */}
                        <div style={{ width: '100%' }}>
                            <EuiListGroup flush>
                            {playlists.map(playlist => (
                                <div key={playlist.id}>
                                        <EuiFlexGroup alignItems="center" gutterSize="s" direction="row">
                                            <EuiFlexItem grow={1}>
                                                <EuiListGroupItem
                                                    className={`playlist-item ${selectedPlaylist === playlist.id ? 'selected-playlist' : ''}`}
                                                    label={TranslateLanguage(localStorage.userLanguage, playlist.name)}
                                                    onClick={() => handlePlaylistChange(playlist.id)}
                                                    iconType={expandedPlaylist === playlist.id ? 'arrowDown' : 'arrowRight'}
                                                />
                                            </EuiFlexItem>
                                        </EuiFlexGroup>
                                        {expandedPlaylist === playlist.id && (
                                            <EuiDragDropContext onDragEnd={onDragEnd}>
                                                <EuiDroppable droppableId="DROPPABLE_AREA" spacing="none">
                                                    {songs.map((song, index) => (
                                                        <EuiDraggable spacing="none" key={song.resourceId} index={index} draggableId={song.name} paddingSize="none" hasBorder={true}>
                                                            {(provided, state) => (
                                                                 <EuiPanel hasShadow={state.isDragging}  paddingSize="none" color={currentSong.resourceId === song.resourceId ? "danger" : "none"} hasBorder={true}>
                                                                    <EuiFlexGroup alignItems="center" gutterSize="s" style={{ flexWrap: 'nowrap' }}>
                                                                        <EuiFlexItem grow={false} style={{ flexShrink: 0, flexBasis: '20px', minWidth: '20px', maxWidth: '20px' }}>
                                                                            <EuiIcon type="grab" />
                                                                        </EuiFlexItem>
                                                                        <EuiFlexItem grow={true} style={{ flexShrink: 1, flexBasis: '100%' }}>
                                                                            <EuiListGroupItem
                                                                                className={`song-item ${currentSong && currentSong.resourceId === song.resourceId ? 'playing-song' : ''}`}
                                                                                label={
                                                                                    <>
                                                                                    {TranslateLanguage(localStorage.userLanguage, song.resourceType)}: 
                                                                                    {song.name.length > 9 ? TranslateLanguage(localStorage.userLanguage, song.name.substring(0, 9)) + '...' : TranslateLanguage(localStorage.userLanguage, song.name)}
                                                                                    </>
                                                                                }
                                                                                icon={
                                                                                    <>
                                                                                        <EuiIcon
                                                                                            type="cross"
                                                                                            // className="delete-icon"
                                                                                            onClick={(e) => {
                                                                                            e.stopPropagation();
                                                                                            handleDeleteSong(song);
                                                                                            }}
                                                                                            style={{ marginLeft: '0px', marginRight: '10px', cursor: 'pointer' }}
                                                                                        />
                                                                                        <EuiIcon type={song.labels === "v" ? "play" : "document"} style={{ marginRight: '5px' }} /> 
                                                                                    </>
                                                                                }
                                                                                onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    e.stopPropagation();
                                                                                    e.currentTarget.focus();  // 强制获取焦点
                                                                                    handleSongClick(song);
                                                                                }}
                                                                                // onTouchEnd={(e) => {
                                                                                //     e.preventDefault();
                                                                                //     e.stopPropagation();
                                                                                //     e.currentTarget.focus();  // 强制获取焦点
                                                                                //     handleSongClick(song);
                                                                                // }}
                                                                                // extraAction={{
                                                                                //     iconType: 'trash',
                                                                                //     onClick: (e) => {
                                                                                //         e.stopPropagation();
                                                                                //         handleDeleteSong(song);
                                                                                //     },
                                                                                //     'aria-label': '删除收藏項目',
                                                                                //     style: { marginLeft: 'auto', cursor: 'pointer' }
                                                                                // }}
                                                                            />
                                                                        </EuiFlexItem>
                                                                    </EuiFlexGroup>
                                                                </EuiPanel>
                                                            )}
                                                        </EuiDraggable>
                                                    ))}
                                                </EuiDroppable>
                                            </EuiDragDropContext>
                                        )}
                                    </div>
                                ))}
                            </EuiListGroup>

                            <div style={{ width: '100%', padding: '5px', display: 'flex', justifyContent: 'space-evenly' }}>
                                {selectedPlaylist && (
                                    <><EuiButtonEmpty iconType="advancedSettingsApp" size="s" onClick={handleEditClick}>
                                        {TranslateLanguage(localStorage.userLanguage, "收藏單詳情")}
                                        </EuiButtonEmpty><EuiButtonEmpty iconType="share" size="s" onClick={handleSharePlaylistClick}>
                                            {TranslateLanguage(localStorage.userLanguage, "分享收藏單")}
                                        </EuiButtonEmpty></>
                                )}
                            </div>
                            <div style={{ width: '100%', padding: '5px', display: 'flex', justifyContent: 'space-evenly' }}>
                                {selectedPlaylist && (
                                    <>  
                                        <EuiButtonEmpty iconType="trash" size="s" onClick={handleDeletePlaylistClick}>
                                            {TranslateLanguage(localStorage.userLanguage, "刪除收藏單")}
                                        </EuiButtonEmpty>
                                    </>
                                )}
                                <EuiButtonEmpty iconType="listAdd" size="s" onClick={openCreatePlaylistModal}>
                                  {TranslateLanguage(localStorage.userLanguage, "新建收藏單")}
                                </EuiButtonEmpty>
                            </div>                                                                                                                
                        </div>
                    </EuiPanel>
                </EuiFlexItem>
            )}

            {/* 如果生成了分享链接，显示链接和复制按钮 */}
            {shareLink && (
                <EuiModal onClose={() => setShareLink(false)}
                        style={{
                            maxWidth: '90%',
                            height: '90%',
                            minHeight: '50vh',
                            margin: 'auto'
                        }}
                >
                    <EuiModalHeader>
                        <h2 style={{ textAlign: 'center', width: '100%' }}>{TranslateLanguage(localStorage.userLanguage, "分享鏈接")}</h2>
                    </EuiModalHeader>
                
                    <EuiModalBody style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                        <div style={{ overflowX: 'auto', wordBreak: 'break-all', maxWidth: '100%' }}>
                            <p style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>{shareLink}</p>
                        </div>
                    </EuiModalBody>

                    <EuiModalFooter style={{ display: 'flex'}}>
                        <EuiButton fill onClick={() => copyShareLink(shareLink)}>{TranslateLanguage(localStorage.userLanguage, "複製鏈接")}</EuiButton>
                        <EuiButton onClick={() => setShareLink(false)} color="danger">{TranslateLanguage(localStorage.userLanguage, "關閉")}</EuiButton>
                    </EuiModalFooter>
                </EuiModal>
            )}

            {/* 编辑收藏单模态窗口 */}
            {isEditModalVisible && (
                <EuiModal onClose={() => setIsEditModalVisible(false)}>
                    <EuiModalHeader>
                        <h2>{TranslateLanguage(localStorage.userLanguage, "收藏單詳情")}</h2>
                    </EuiModalHeader>
                    <EuiModalBody>
                        <EuiFieldText
                            placeholder={TranslateLanguage(localStorage.userLanguage, "收藏單名稱")}
                            value={TranslateLanguage(localStorage.userLanguage, newPlaylistName)}
                            onChange={(e) => setNewPlaylistName(e.target.value)}
                            required
                            aria-label="收藏單名稱"  // 增加無障礙支持
                            isInvalid={!newPlaylistName.trim()} // 若名称为空，设置为无效状态
                        />
                        <EuiSelect
                            options={[
                                { value: 'public', text: TranslateLanguage(localStorage.userLanguage, "公開")},
                                { value: 'private', text: TranslateLanguage(localStorage.userLanguage, "私有")},
                                { value: 'shareable', text: TranslateLanguage(localStorage.userLanguage, "可分享但不加入搜索列表")}
                            ]}
                            value={newPlaylistVisibility}
                            onChange={(e) => setNewPlaylistVisibility(e.target.value)}
                        />
                        <EuiTextArea
                            placeholder={TranslateLanguage(localStorage.userLanguage, "收藏單描述")}
                            value={newPlaylistDescription}
                            onChange={(e) => setNewPlaylistDescription(e.target.value)}
                        />
                        <EuiFieldText
                            placeholder={TranslateLanguage(localStorage.userLanguage, "標籤(用逗號分隔)")}
                            value={newPlaylistTags}
                            onChange={(e) => setNewPlaylistTags(e.target.value)}
                        />
                        <EuiFieldText
                            placeholder={TranslateLanguage(localStorage.userLanguage, "分類")}
                            value={newPlaylistCategory}
                            onChange={(e) => setNewPlaylistCategory(e.target.value)}
                        />
                    </EuiModalBody>
                    <EuiModalFooter>
                        <EuiButton 
                            onClick={() => {
                                if (newPlaylistName.trim()) {
                                    handleSaveChanges();
                                } else {
                                    showAlert(TranslateLanguage(localStorage.userLanguage, "收藏單名稱為必填項"));
                                }
                            }} 
                            fill
                        >
                            {TranslateLanguage(localStorage.userLanguage, "保存更改")}
                        </EuiButton>
                        <EuiButton onClick={() => setIsEditModalVisible(false)} color="danger">{TranslateLanguage(localStorage.userLanguage, "取消")}</EuiButton>                  
                    </EuiModalFooter>
                </EuiModal>
            )}


            {/* 新建收藏单模态窗口 */}
            {showCreateModal && (
            <EuiModal onClose={closeCreatePlaylistModal}>
                <EuiModalHeader>
                <h2>{TranslateLanguage(localStorage.userLanguage, "創建新的收藏單")}</h2>
                </EuiModalHeader>
                <EuiModalBody>
                <EuiFieldText
                    placeholder={TranslateLanguage(localStorage.userLanguage, "收藏單名稱")}
                    value={TranslateLanguage(localStorage.userLanguage, newPlaylistName)}
                    onChange={e => setNewPlaylistName(e.target.value)}
                    required
                    aria-label="收藏單名稱"  // 增加無障礙支持
                    isInvalid={!newPlaylistName.trim()} // 若名称为空，设置为无效状态
                />
                <EuiSelect
                    options={[
                        { value: 'public', text: TranslateLanguage(localStorage.userLanguage, "公開")},
                        { value: 'private', text: TranslateLanguage(localStorage.userLanguage, "私有")},
                        { value: 'shareable', text: TranslateLanguage(localStorage.userLanguage, "可分享但不加入搜索列表")}
                    ]}
                    value={newPlaylistVisibility}
                    onChange={e => setNewPlaylistVisibility(e.target.value)}
                />
                <EuiTextArea
                    placeholder={TranslateLanguage(localStorage.userLanguage, "收藏單描述")}
                    value={newPlaylistDescription}
                    onChange={e => setNewPlaylistDescription(e.target.value)}
                />
                <EuiFieldText
                    placeholder={TranslateLanguage(localStorage.userLanguage, "標籤(用逗號分隔)")}
                    value={newPlaylistTags}
                    onChange={e => setNewPlaylistTags(e.target.value)}
                />
                <EuiFieldText
                    placeholder={TranslateLanguage(localStorage.userLanguage, "分類")}
                    value={newPlaylistCategory}
                    onChange={e => setNewPlaylistCategory(e.target.value)}
                />
                </EuiModalBody>
                <EuiModalFooter>
                <EuiButton 
                        onClick={() => {
                            if (newPlaylistName.trim()) {
                                handleCreatePlaylist();
                            } else {
                                showAlert(TranslateLanguage(localStorage.userLanguage, "收藏單名稱為必填項"));
                            }
                        }} 
                        fill
                    >
                        {TranslateLanguage(localStorage.userLanguage, "創建")}
                </EuiButton>
                <EuiButton onClick={closeCreatePlaylistModal} color="danger">{TranslateLanguage(localStorage.userLanguage, "取消")}</EuiButton>
                </EuiModalFooter>
            </EuiModal>
            )}

        </EuiFlexGroup>
    );
}

export default FavoritePageWithPlayer;
