import React, { useState, useEffect, memo } from 'react';
import { EuiModal, EuiModalHeader, EuiModalBody, EuiModalFooter, EuiButton, EuiSelect } from '@elastic/eui';
import {TranslateLanguage} from './KeywordTranslator';
import axios from 'axios';
import { useAlert } from './AlertProvider';

const PlaylistModal = memo(({ userId, songId, videoURL, type, engineId, title, label,playlisttype,closeModal,onSaveSuccess  }) => {
    const [selectedPlaylistId, setSelectedPlaylistId] = useState('');
    const [playlists, setPlaylists] = useState([]);
    const showAlert = useAlert();

    // const extractSongIdFromUrl = (url) => {
    //     const match = url.match(/v=([^&]+)/);
    //     return match ? match[1] : null;
    // };

    const extractSongIdFromUrl = (url) => {
        // Define a regular expression to match different YouTube URL formats
        const regex = /(?:youtu\.be\/|youtube\.com\/(?:watch\?v=|embed\/|v\/|.+?v=))([^?&\/\s]+)/;
        
        // Apply the regex to the input URL
        const match = url.match(regex);
        
        // Return the captured video ID or null if no match is found
        return match ? match[1] : null;
    }

    const songId_new = extractSongIdFromUrl(videoURL);
    // console.log("Extracted songId:", songId_new);

    useEffect(() => {
        axios.get(`/api/playlists`, {
            params: {
                type: playlisttype,
                userId: userId
            }
        })
        .then(response => {
            const data = response.data;
            // 如果 data 不是数组，则将其设置为空数组
            if (!Array.isArray(data)) {
                console.error('Fetched playlists data is not an array:', data);
                setPlaylists([]);
            } else {
                setPlaylists(data);
                // 默认选择第一个歌单
                if (data.length > 0) {
                    setSelectedPlaylistId(data[0].id);
                }
                else {
                    showAlert(TranslateLanguage(localStorage.userLanguage, "請進入我的歌單頁面創建一個歌單！"));
                    closeModal(); // 关闭模态框
                }
            }
        })
        .catch(error => {
            console.error('Error fetching playlists:', error);
        });
    }, [userId]);

    const handleSave = () => {
        if (selectedPlaylistId) {
            axios.post(`/api/playlists/${selectedPlaylistId}/addsongs`, {
                resourceId: songId_new,
                name: title,
                link: videoURL,
                type: type,
                engineId: engineId
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    // Authorization 由全局拦截器自动处理
                }
            })
            .then(response => {
                console.log('Song added to playlist:', response.data);
                closeModal(); // 关闭模态框
            })
            .catch(error => {
                console.error('Error saving song to playlist:', error);
            });
        }
    };

    const handleAddSongToPlaylist = () => {
        const songId = extractSongIdFromUrl(videoURL);
        if (selectedPlaylistId) {
        // 获取当前歌单中的歌曲列表
        axios.get(`/api/playlists/${selectedPlaylistId}/songs`)
        .then(response => {
            let songs = response.data || []; // 如果 songs 是空数组或 undefined，设置为一个空数组

            // 检查当前歌单中是否已经有相同的 songId
            const songExists = songs.some(song => song.resourceId === songId);

            if (!songExists) {
                // 如果歌单中没有该 songId，则添加歌曲
                const requestBody = {
                    ResourceId: songId,
                    Name: title,
                    Link: videoURL,
                    type: type,
                    label: label,
                    engineId: engineId
                };

                axios.post(`/api/playlists/${selectedPlaylistId}/addsongs`, requestBody, {
                    headers: {
                        'Content-Type': 'application/json',
                        // Authorization 由全局拦截器自动处理
                    }
                })
                .then(response => {
                    if (response.status === 200) {
                        // console.log("Song added to playlist successfully");
                        showAlert(TranslateLanguage(localStorage.userLanguage, "保存成功"));
                         // Notify parent about the success
                        if (onSaveSuccess) {
                            onSaveSuccess(); // Invoke the callback
                        }
                    } else {
                        // console.error("Failed to add song to playlist");
                        showAlert(TranslateLanguage(localStorage.userLanguage, "保存失敗"));
                    }
                    closeModal(); // 关闭模态框
                })
                .catch(error => {
                    console.error("Error adding song to playlist:", error);
                });
            } else {
                console.log("Song already exists in the playlist");
                showAlert(TranslateLanguage(localStorage.userLanguage, "詩歌已在歌單中"));
                closeModal(); // 关闭模态框
            }
        })
        .catch(error => {
            console.error("Error fetching playlist songs:", error);
        });
        }
    };

    return (
        <EuiModal onClose={closeModal}>
            <EuiModalHeader>{TranslateLanguage(localStorage.userLanguage, "選擇歌單")}</EuiModalHeader>
            <EuiModalBody>
                <EuiSelect
                    options={playlists.map(playlist => ({
                        value: playlist.id,
                        text: playlist.name
                    }))}
                    value={selectedPlaylistId}
                    onChange={e => setSelectedPlaylistId(e.target.value)}
                />
            </EuiModalBody>
            <EuiModalFooter>
                <EuiButton onClick={handleAddSongToPlaylist} fill>{TranslateLanguage(localStorage.userLanguage, "保存")}</EuiButton>
            </EuiModalFooter>
        </EuiModal>       
    );
});

export default PlaylistModal;
